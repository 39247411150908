import React, { Component } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import L from "leaflet";
import { MapContainer, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import hash from "object-hash";
import Axios from "../../redux/axiosHandlerGeo";
import GeoJsonLayer from "./GeoJsonLayer";
import kanchanpur from "../../geojson/kanchanpur.json";
import { infastructure } from "../../variable/global";
// import kanchanpur from "../../geojson/bedkot.json";
import "leaflet/dist/leaflet.css";
import { connect } from "react-redux";

class MunicipalityDescription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalShow: false,
			boundData: [],
			wholeGeojson: [],
			location: {
				lat: 28.96725102178788,
				lng: 80.18087073179544,
			},
			zoom: 11,
			checkbox: {},
			wholeGeoJSON: {},
			showGeoJSON: [],
			sectionTitle: "",
		};
	}
	// 	this.getMunicpalId = this.getMunicpalId.bind(this);
	// }

	// isGeoJsonAlreadyFetched = (field) => {
	// 	const { wholeGeoJSON } = this.state;
	// 	return Boolean(wholeGeoJSON[field]);
	// };

	// fetchGeoJSON = (changedData) => {
	// 	const body = {
	// 		industries: "institute/industries",
	// 		cultural_heritage: "institute/cultural_heritage",
	// 		gov_office: "institute/gov_office",
	// 		forest: "institute/forest",
	// 		offices: "institute/offices",
	// 		infrastructure: "institute/infrastructure",
	// 		economic: "institute/economic",
	// 		education: "institute/education",
	// 		security: "institute/security",
	// 		palika_related_office: "institute/palika_related_office",
	// 		public_places: "institute/public_places",
	// 		health: "institute/health",
	// 	};

	// 	return new Promise((resolve, reject) => {
	// 		Axios.get(`/api/geojson/${body[changedData]}`)
	// 			.then((result) => {
	// 				const wholeGeoJSON = this.state.wholeGeoJSON;
	// 				this.setState(
	// 					{
	// 						wholeGeoJSON: {
	// 							...wholeGeoJSON,
	// 							[changedData]: result.data,
	// 						},
	// 					},
	// 					() => resolve()
	// 				);
	// 			})
	// 			.catch((err) => reject());
	// 	});
	// };

	// arrangeAllOption = async () => {
	// 	const { checkbox } = this.state;

	// 	const showGeoJSON = [];
	// 	let dataToCheck = [];

	// 	dataToCheck = dataToCheck.concat(infastructure);
	// 	const data = dataToCheck.map(async (each, i) => {
	// 		if (checkbox[each.value]) {
	// 			if (!this.isGeoJsonAlreadyFetched(each.value))
	// 				await this.fetchGeoJSON(each.value);

	// 			showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
	// 		}
	// 	});
	// 	// wait until all promises resolve
	// 	await Promise.all(data);

	// 	this.setState({ showGeoJSON });
	// };

	// handleChange = (e) => {
	// 	e.persist();
	// 	this.setState(
	// 		({ checkbox }) => ({
	// 			checkbox: {
	// 				...checkbox,
	// 				[e.target.name]: e.target.checked,
	// 			},
	// 		}),
	// 		this.arrangeAllOption
	// 	);
	// };

	// style(feature) {
	// 	let skin = "blue";
	// 	if (feature.properties.FIRST_GaPa === "Bhimdatta") {
	// 		skin = "green";
	// 	}
	// 	return {
	// 		fillColor: skin,
	// 		weight: 1,
	// 		opacity: 1,
	// 		color: "black",
	// 		fillOpacity: 0.3,
	// 	};
	// }

	componentDidMount() {
		const boundData = [
			{
				key: "पुर्व",
				keyEnglish: "East",
				value: "चीन",
				valueEnglish: "China",
				class: "col-md-12",
			},
			{
				key: "उत्तर",
				keyEnglish: "North",
				value: "चीन",
				valueEnglish: "China",
				class: "col-md-12",
			},
			{
				key: "पश्चिम",
				keyEnglish: "West",
				value: "चीन",
				valueEnglish: "China",
				class: "col-md-12",
			},
			{
				key: "दक्षिण",
				keyEnglish: "South",
				value: "लो घेकर दामोदरकुण्ड गाउँपालिका",
				valueEnglish: "Lo Ghekar Damodarkund Rural municipality",
				class: "col-md-12",
			},
			{
				key: "क्षेत्रफल",
				keyEnglish: "Area",
				value: "७२७ वर्ग कि.मि",
				valueEnglish: "727 km",
				class: "col-md-12",
			},
			{
				key: " वडा संख्या",
				keyEnglish: "ward",
				value: "५",
				valueEnglish: "5",
				class: "col-md-12",
			},
			{
				key: "अक्षांश तथा देशान्तर",
				keyEnglish: "latitude and longitude",
				value: "२९°१०′५९″N , ८३°५७′२४″E",
				valueEnglish: "29°10′59″N , 83°57′24″E",
				class: "col-12",
			},
			// {
			// 	key: "मुख्य सहरी क्षेत्र",
			// 	keyEnglish: "Main city area",
			// 	value: "",
			// 	class: "col-12",
			// },
		];
		this.setState({ ...this.state, boundData });
	}
	getMunicipalBounds() {
		let modalClose = () => this.setState({ modalShow: false });
		return (
			<div className="col-lg-5 pb-5 d-flex">
				<Card>
					<Card.Body>
						<div className="d-flex pb-8">
							<SVG
								className="primary-icon"
								src={toAbsoluteUrl("media/hamropalika/svg/map_marker.svg")}
								alt=""
							/>
							<h2 className="font-weight-black pl-3 pt-6">
								{this.props.selectedLanguage == "nepali"
									? "सिमाना"
									: "Boundaries"}
							</h2>
						</div>
						<div className="row">
							{this.state.boundData.map((data, index) => {
								return (
									<div className={`pb-3 ${data.class}`} key={index}>
										<div className="row">
											<div className="col-4">
												<h5 className="pr-2">
													{this.props.selectedLanguage == "nepali"
														? data.key
														: data.keyEnglish}
													:
												</h5>
											</div>
											<div className="col-8">
												<h5 className="text-primary">
													{this.props.selectedLanguage == "nepali"
														? data.value
														: data.valueEnglish}
												</h5>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
	getMunicpalId() {
		return (
			<div className="col-lg-7 pb-5 d-flex ">
				<Card>
					<Card.Body>
						<div className="d-flex pb-8">
							<SVG
								className="primary-icon"
								src={toAbsoluteUrl("media/hamropalika/svg/municipality_id.svg")}
								alt=""
							/>
							<h2 className="font-weight-black pl-3 pt-6">
								{this.props.selectedLanguage == "nepali"
									? "गाउँपालिका अबस्थित  नक्शा"
									: "Rural Municipality Location Map"}
							</h2>
						</div>

						<img
							className="w-100"
							src={toAbsoluteUrl("/media/hamropalika/lomangthangStatic.png")}
							alt=""
						/>
					</Card.Body>
				</Card>
			</div>
		);
	}
	render() {
		return (
			<>
				<div className="row">
					{this.getMunicpalId()}
					{this.getMunicipalBounds()}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(MunicipalityDescription);
