import React, { Component } from "react";
import PersonCard from "../components/PersonCard";

class Staff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		const people = [
			{
				name: "बुद्धिराम उपाध्याय",
				nameEnglish: "Buddhiram Upadhyaya",
				designation: "प्रमुख प्रशासकीय अधिकृत",
				designationEnglish: "Chief Administrative Officer",
				email: "upadhyaybuddhiram27@gmail.com",
				phone: "९८५७६३०३३०",
				phoneEnglish: "9857630330",
				address: "लोमन्थाङ गाउँपालिका, गण्डकी प्रदेश,मुस्ताङ",
				addressEnglish: "Lomangthang rural municipality, mustang",
				image: "media/hamropalika/people/st3.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "बिकाश के.सी.",
				nameEnglish: "Bikash kc",
				designation: "सूचना अधिकारी",
				designationEnglish: "Information Officer",
				email: "suchanaadhikarilomanthang@gmail.com",
				phone: "९८५७६३६८४२",
				phoneEnglish: "9857636842",
				address: "लोमन्थाङ गाउँपालिका, गण्डकी प्रदेश,मुस्ताङ",
				addressEnglish: "Lomangthang rural municipality, mustang",
				image: "media/hamropalika/people/st4.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
		];
		this.setState({ people });
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Staff;
