export const gaupalikaNepali = "लोमन्थाङ";
export const gaupalikaEnglish = "Lomangthang";
export const ruralMunicipalNepali = "गाउँपालिका";
export const ruralMunicipalEnglish = "Rular Municipality";
export const gaupalika = { nepali: gaupalikaNepali, english: gaupalikaEnglish };
// export const infastructure = [
// 	{
// 		icon: "book",
// 		english: "Educational Institution",
// 		nepali: "शैक्षिक सँस्था",
// 		children: [
// 			// {
// 			// 	value: "private",
// 			// 	english: "Private",
// 			// 	nepali: "निजि",
// 			// },
// 			{
// 				value: "primary",
// 				english: "Primary",
// 				nepali: "प्राथमिक",
// 			},
// 			{
// 				value: "secondary",
// 				english: "Secondary",
// 				nepali: "माध्यमिक",
// 			},
// 			// {
// 			// 	value: "montessori",
// 			// 	english: "Montessori",
// 			// 	nepali: "मोन्टेसरी",
// 			// },
// 			{
// 				value: "college_technical",
// 				english: "Technical College",
// 				nepali: "टेक्निकल कलेज",
// 			},
// 			// {
// 			// 	value: "college_nontechnical",
// 			// 	english: "Nontechnical College",
// 			// 	nepali: "कलेज",
// 			// },
// 			// {
// 			// 	value: "skill_center",
// 			// 	english: "Skill Center",
// 			// 	nepali: "कौशल केन्द्र",
// 			// },
// 			// {
// 			// 	value: "university",
// 			// 	english: "University",
// 			// 	nepali: "विश्वविद्यालय",
// 			// },
// 			// {
// 			// 	value: "community",
// 			// 	english: "Community",
// 			// 	nepali: "सामुदायिक",
// 			// },
// 			// {
// 			// 	value: "madrasa",
// 			// 	english: "Madrasa",
// 			// 	nepali: "मदरसा",
// 			// },
// 			// {
// 			// 	value: "gumba",
// 			// 	english: "Gumba",
// 			// 	nepali: "गुम्बा",
// 			// },
// 			// {
// 			// 	value: "veda",
// 			// 	english: "Vidya Asram",
// 			// 	nepali: "वेद विद्याश्रम",
// 			// },
// 			// {
// 			// 	value: "library",
// 			// 	english: "Library",
// 			// 	nepali: "पुस्तकालय",
// 			// },
// 		],
// 	},
// 	{
// 		icon: "hospital",
// 		english: "Health Institution",
// 		nepali: "स्वास्थ केन्द्र",
// 		children: [
// 			// {
// 			// 	value: "hospital",
// 			// 	english: "Hospital",
// 			// 	nepali: "अस्पताल",
// 			// },
// 			{
// 				value: "healthPost",
// 				english: "Health Post",
// 				nepali: "स्वाथ्य चौकी",
// 			},
// 			// {
// 			// 	value: "urban_helath_center",
// 			// 	english: "Urban Helath Center",
// 			// 	nepali: "शहरी स्वास्थ्य केन्द्र",
// 			// },
// 			{
// 				value: "basic_health_center",
// 				english: "Basic Health Center",
// 				nepali: "आधारभूत स्वास्थ्य सेवा केन्द्र",
// 			},
// 			{
// 				value: "village_clinic",
// 				english: "Village Clinic",
// 				nepali: "गाउँ घर क्लिनिक",
// 			},
// 			// {
// 			// 	value: "helpdesk",
// 			// 	english: "Helpdesk",
// 			// 	nepali: "हेल्पडेस्क",
// 			// },
// 			// {
// 			// 	value: "maternity",
// 			// 	english: "Maternity",
// 			// 	nepali: "प्रसुती केन्द्र",
// 			// },
// 			// {
// 			// 	value: "clinic",
// 			// 	english: "Clinic",
// 			// 	nepali: "क्लिनिक",
// 			// },
// 			// {
// 			// 	value: "ayurved",
// 			// 	english: "Ayurved",
// 			// 	nepali: "आयुर्वेद",
// 			// },
// 		],
// 	},
// 	{
// 		icon: "infrastructure",
// 		english: "Infrastructure",
// 		nepali: "भौतिक पूर्वाधार",
// 		children: [
// 			{
// 				value: "bridge",
// 				english: "Bridge",
// 				nepali: "पुल",
// 			},
// 			// {
// 			// 	value: "fm_station",
// 			// 	english: "F.M Station",
// 			// 	nepali: "एफ.एम स्टेशन",
// 			// },
// 			{
// 				value: "tower",
// 				english: "Tower (NTC/NCELL/OTHER)",
// 				nepali: "संचार टावर (NTC/NCELL/OTHER)",
// 			},
// 			{
// 				value: "water_tank",
// 				english: "Water Tank",
// 				nepali: "खानेपानी ट्यांकी भएको स्थान",
// 			},
// 			{
// 				value: "community_building",
// 				english: "Community Building",
// 				nepali: "सामुदायिक भवन",
// 			},
// 			{
// 				value: "calvert",
// 				english: "Calvert",
// 				nepali: "कल्भर्ट",
// 			},
// 			// {
// 			// 	value: "internet_provider",
// 			// 	english: "Internet Provider",
// 			// 	nepali: "इन्टरनेट प्रदायक संस्था",
// 			// },
// 			// {
// 			// 	value: "stadium",
// 			// 	english: "Stadium",
// 			// 	nepali: "रंगशाला",
// 			// },
// 			// {
// 			// 	value: "sabhaa_hal",
// 			// 	english: "Sabhaa Hall",
// 			// 	nepali: "सभा हल",
// 			// },
// 			// {
// 			// 	value: "airport",
// 			// 	english: "Airport",
// 			// 	nepali: "विमानस्थल",
// 			// },
// 			// {
// 			// 	value: "tv_station",
// 			// 	english: "T.V Station",
// 			// 	nepali: "टिभी स्टेशन",
// 			// },
// 			// {
// 			// 	value: "powerhouse",
// 			// 	english: "Power House",
// 			// 	nepali: "पावरहाउस (जलविधुत आयोजना)",
// 			// },
// 			// {
// 			// 	value: "powerstation",
// 			// 	english: "Power Station",
// 			// 	nepali: "पावरस्टेशन",
// 			// },
// 		],
// 	},
// 	{
// 		icon: "banking",
// 		english: "Financial Institutions",
// 		nepali: "बैंक तथा वित्तीय संस्था",
// 		children: [
// 			{
// 				value: "a",
// 				english: "A Class",
// 				nepali: "क बर्ग",
// 			},
// 			{
// 				value: "b",
// 				english: "B Class",
// 				nepali: "ख बर्ग",
// 			},
// 			{
// 				value: "c",
// 				english: "C CLass",
// 				nepali: "ग वर्ग",
// 			},
// 		],
// 	},
// 	{
// 		icon: "park",
// 		english: "Public Places",
// 		nepali: "सार्वजनिक स्थल",
// 		children: [
// 			{
// 				value: "public_toilet",
// 				english: "Public Toilet",
// 				nepali: "सर्वाजनिक सौचालय",
// 			},
// 			{
// 				value: "free_wifi",
// 				english: "Free Wifi",
// 				nepali: "फ्री वाईफाई जोन",
// 			},
// 			{
// 				value: "buspark",
// 				english: "Buspark",
// 				nepali: "वसपार्क",
// 			},
// 			{
// 				value: "park",
// 				english: "Park",
// 				nepali: "पार्क",
// 			},
// 			{
// 				value: "zoo",
// 				english: "Zoo",
// 				nepali: "चिडिया घर",
// 			},
// 		],
// 	},
// 	{
// 		icon: "government",
// 		english: "Government Offices",
// 		nepali: "सरकारी कार्यालयहरु",
// 		children: [
// 			{
// 				value: "land_revenue",
// 				english: "Land Revenue",
// 				nepali: "मालपोत कार्यालय",
// 			},
// 			{
// 				value: "dao",
// 				english: "District Office",
// 				nepali: "जील्ला प्रशासन कार्यालय",
// 			},
// 			{
// 				value: "court",
// 				english: "Court",
// 				nepali: "अदालत",
// 			},
// 			{
// 				value: "ministries",
// 				english: "Ministries",
// 				nepali: "मंत्रालय",
// 			},
// 			{
// 				value: "electricity",
// 				english: "Electricity Office",
// 				nepali: "विधुत प्राधिकरण कार्यालय",
// 			},
// 			{
// 				value: "dcc",
// 				english: "District Council",
// 				nepali: "जिल्ला समन्व समिति कार्यालय",
// 			},
// 		],
// 	},
// 	{
// 		icon: "building",
// 		english: "Organizations",
// 		nepali: "संघ सँस्थाहरु",
// 		children: [
// 			{
// 				value: "ngo",
// 				english: "NGO",
// 				nepali: "गैरसरकारी",
// 			},
// 			{
// 				value: "ingo",
// 				english: "INGO",
// 				nepali: "अन्तर्राष्ट्रिय  गैरसरकारी",
// 			},
// 			{
// 				value: "private",
// 				english: "Private / Public",
// 				nepali: "निजी / पब्लीक",
// 			},
// 		],
// 	},
// 	{
// 		icon: "factory",
// 		english: "Industries",
// 		nepali: "उधोग तथा कलकारखाना",
// 		children: [
// 			{
// 				value: "brick_industry",
// 				english: "Brick Industry",
// 				nepali: "इँटा भट्टा",
// 			},
// 			{
// 				value: "sugar_mill",
// 				english: "Sugar Mill",
// 				nepali: "चिनी उधोग",
// 			},
// 			{
// 				value: "mill",
// 				english: "Mill",
// 				nepali: "मिल",
// 			},
// 		],
// 	},
// 	{
// 		icon: "police-station",
// 		english: "Security body Offices",
// 		nepali: "सुरक्षा निकाय सम्बन्धी",
// 		children: [
// 			{
// 				value: "police",
// 				english: "Police Station",
// 				nepali: "प्रहरी चौकी",
// 			},
// 			{
// 				value: "apf",
// 				english: "APF Camp",
// 				nepali: "एपीएफ व्यारेक",
// 			},
// 			{
// 				value: "army",
// 				english: "Army Camp",
// 				nepali: "सैनिक व्यारेक",
// 			},
// 			{
// 				value: "traffic",
// 				english: "Traffic Office",
// 				nepali: "ट्राफिक कार्यालय",
// 			},
// 		],
// 	},
// 	{
// 		icon: "nature",
// 		english: "Natural Resources",
// 		nepali: "प्राकृतिक श्रोत",
// 		children: [
// 			{
// 				value: "mining",
// 				english: "Mine Area",
// 				nepali: "खानी क्षेत्र",
// 			},
// 			{
// 				value: "waterfall",
// 				english: "Waterfall",
// 				nepali: "झरना",
// 			},
// 			{
// 				value: "cave",
// 				english: "Cave",
// 				nepali: "गुफा",
// 			},
// 			{
// 				value: "lake",
// 				english: "Lake",
// 				nepali: "ताल",
// 			},
// 			{
// 				value: "pond",
// 				english: "Pond",
// 				nepali: "पोखरी",
// 			},
// 			{
// 				value: "main_water_source",
// 				english: "Main Watersource",
// 				nepali: "मुख्य पानी मुहान",
// 			},
// 		],
// 	},
// 	// {
// 	// 	icon: "book",
// 	// 	value: "industries",
// 	// 	english: "Industries",
// 	// 	nepali: "उधोग तथा कलकारखाना",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "cultural_heritage",
// 	// 	english: "Cultural Heritage",
// 	// 	nepali: "साँस्कृतिक सम्पदाहरु",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "gov_office",
// 	// 	english: "Government Offices",
// 	// 	nepali: "सरकारी कार्यालयहरु",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "forest",
// 	// 	english: "Forest",
// 	// 	nepali: "सामुदायिक वन",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "education",
// 	// 	english: "Educational Institution",
// 	// 	nepali: "शैक्षिक सँस्था",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "offices",
// 	// 	english: "Organizations",
// 	// 	nepali: "संघ सँस्थाहरु",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "infrastructure",
// 	// 	english: "Infrastructure",
// 	// 	nepali: "भौतिक पूर्वाधार",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "security",
// 	// 	english: "Security body Offices",
// 	// 	nepali: "सुरक्षा निकाय सम्बन्धी",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "economic",
// 	// 	english: "Financial Institutions",
// 	// 	nepali: "बैंक तथा वित्तीय संस्था",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "palika_related_office",
// 	// 	english: "Municipal and Ward Office",
// 	// 	nepali: "गाउँ/नगर केन्द्र र वडा कार्यालय ",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "public_places",
// 	// 	english: "Public Places",
// 	// 	nepali: "सार्वजनिक स्थल",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "health",
// 	// 	english: "Health Institution",
// 	// 	nepali: "स्वास्थ केन्द्र",
// 	// },
// ];

export const infastructure = [
	{
		icon: "book",
		english: "Educational Institution",
		nepali: "शैक्षिक सँस्था",
		children: [
			// {
			// 	value: "private",
			// 	english: "Private",
			// 	nepali: "निजि",
			// },
			{
				value: "montessori",
				english: "Child Development Center",
				nepali: "बालविकास केन्द्र",
			},
			{
				value: "primary",
				english: "Primary School",
				nepali: "आधारभूत बिद्यालय",
			},
			{
				value: "secondary",
				english: "Secondary School",
				nepali: "माध्यमिक विद्यालय",
			},

			// {
			// 	value: "college_nontechnical",
			// 	english: "College / Campus",
			// 	nepali: "कलेज / क्याम्पस",
			// },
			// {
			// 	value: "college_nontechnical",
			// 	english: "Nontechnical College",
			// 	nepali: "कलेज",
			// },
			// {
			// 	value: "skill_center",
			// 	english: "Skill Center",
			// 	nepali: "सीप विकास तालिम केन्द्र",
			// },
			// {
			// 	value: "university",
			// 	english: "University",
			// 	nepali: "विश्वविद्यालय",
			// },
			// {
			// 	value: "community",
			// 	english: "Community",
			// 	nepali: "सामुदायिक",
			// },
			// {
			// 	value: "madrasa",
			// 	english: "Madrasa",
			// 	nepali: "मदरसा",
			// },
			// {
			// 	value: "gumba",
			// 	english: "Gumba",
			// 	nepali: "गुम्बा",
			// },
			// {
			// 	value: "veda",
			// 	english: "Vidya Asram",
			// 	nepali: "वेद विद्याश्रम",
			// },
			// {
			// 	value: "library",
			// 	english: "Library",
			// 	nepali: "पुस्तकालय",
			// },
		],
	},
	{
		icon: "hospital",
		english: "Health Institution",
		nepali: "स्वास्थ केन्द्र",
		children: [
			{
				value: "hospital",
				english: "Hospital",
				nepali: "अस्पताल",
			},
			{
				value: "healthPost",
				english: "Health Post",
				nepali: "स्वाथ्य चौकी",
			},
			// {
			// 	value: "primary_heath_center",
			// 	english: "Primary Health Center",
			// 	nepali: "प्राथमिक स्वास्थ्य केन्द्र",
			// },
			// {
			// 	value: "basic_health_center",
			// 	english: "Basic Health Center",
			// 	nepali: "आधारभूत स्वास्थ्य सेवा केन्द्र",
			// },
			// {
			// 	value: "urban_helath_center",
			// 	english: "Urban Helath Center",
			// 	nepali: "शहरी स्वास्थ्य केन्द्र",
			// },
			// {
			// 	value: "village_clinic",
			// 	english: "Village Clinic",
			// 	nepali: "गाउँ घर क्लिनिक",
			// },
			// {
			// 	value: "helpdesk",
			// 	english: "Helpdesk",
			// 	nepali: "हेल्पडेस्क",
			// },
			// {
			// 	value: "maternity",
			// 	english: "Maternity",
			// 	nepali: "प्रसुती केन्द्र",
			// },
			// {
			// 	value: "clinic",
			// 	english: "Clinic",
			// 	nepali: "क्लिनिक",
			// },
			// {
			// 	value: "ayurved",
			// 	english: "Ayurved Medical",
			// 	nepali: "आयुर्वेदिक औसधालय",
			// },
			// {
			// 	value: "natural_hospital",
			// 	english: "Natural Hospital",
			// 	nepali: "प्राकृतिक चिकित्सालय",
			// },
		],
	},
	{
		icon: "infrastructure",
		english: "Infrastructure",
		nepali: "भौतिक पूर्वाधार",
		children: [
			{
				value: "bridge",
				english: "Bridge",
				nepali: "पुल",
			},
			{
				value: "stadium",
				english: "Stadium",
				nepali: "खेल मैदान",
			},
			// {
			// 	value: "powerhouse",
			// 	english: "Powerhouse",
			// 	nepali: "जलविधुत आयोजना",
			// },
			{
				value: "community_building",
				english: "Community Building",
				nepali: "सामुदायिक भवन",
			},
			// {
			// 	value: "airport",
			// 	english: "Airport",
			// 	nepali: "विमानस्थल",
			// },
			// {
			// 	value: "fm_station",
			// 	english: "F.M Station",
			// 	nepali: "एफ.एम स्टेशन",
			// },
			// {
			// 	value: "tv_station",
			// 	english: "T.V Station",
			// 	nepali: "टिभी स्टेशन",
			// },
			// {
			// 	value: "tower",
			// 	english: "Tower (NTC/NCELL/OTHER)",
			// 	nepali: "संचार टावर (NTC/NCELL/OTHER)",
			// },
			// {
			// 	value: "calvert",
			// 	english: "Calvert",
			// 	nepali: "कल्भर्ट",
			// },
			// {
			// 	value: "water_tank",
			// 	english: "Water Tank",
			// 	nepali: "खानेपानी ट्यांकी",
			// },
			{
				value: "sabhaa_hal",
				english: "Sabhaa Hall",
				nepali: "सभा हल",
			},
			{
				value: "sabdahaa_sthal_1",
				english: "Graveyard",
				nepali: "शवदहास्थल",
			},
			{
				value: "animal_cut",
				english: "Cattle Decapitation",
				nepali: "पशुवधशाला",
			},
			// {
			// 	value: "internet_provider",
			// 	english: "Internet Provider",
			// 	nepali: "इन्टरनेट प्रदायक संस्था",
			// },
			// {
			// 	value: "powerstation",
			// 	english: "Power Station",
			// 	nepali: "पावरस्टेशन",
			// },
		],
	},
	{
		icon: "banking",
		english: "Financial Institutions",
		nepali: "बैंक तथा वित्तीय संस्था",
		children: [
			{
				value: "a",
				english: "A Class Banks",
				nepali: "क बर्ग (वाणिज्य बैंक )",
			},
			// {
			// 	value: "b",
			// 	english: "B Class Banks",
			// 	nepali: "ख बर्ग (विकास बैंक )",
			// },
			// {
			// 	value: "c",
			// 	english: "C CLass Banks",
			// 	nepali: "ग वर्ग (वित्तिय सँस्था/ फाइनान्स)",
			// },
			// {
			// 	value: "d",
			// 	english: "D CLass Banks",
			// 	nepali: "घ वर्ग (लघु वित्त सँस्था)",
			// },
		],
	},
	// {
	// 	icon: "park",
	// 	english: "Public Places",
	// 	nepali: "सार्वजनिक स्थल",
	// 	children: [
	// 		{
	// 			value: "public_toilet",
	// 			english: "Public Toilet",
	// 			nepali: "सर्वाजनिक सौचालय",
	// 		},
	// 		{
	// 			value: "free_wifi",
	// 			english: "Free Wifi Zone",
	// 			nepali: "फ्री वाईफाई जोन",
	// 		},
	// 		{
	// 			value: "buspark",
	// 			english: "Buspark",
	// 			nepali: "वसपार्क",
	// 		},
	// 		{
	// 			value: "park",
	// 			english: "Park",
	// 			nepali: "पार्क",
	// 		},
	// 		{
	// 			value: "chautaara",
	// 			english: "Chautara",
	// 			nepali: "चौतारा",
	// 		},
	// 		{
	// 			value: "paati_pauwaa",
	// 			english: "Pati / Powwa",
	// 			nepali: "पाटी / पौवा",
	// 		},
	// 		// {
	// 		// 	value: "zoo",
	// 		// 	english: "Zoo",
	// 		// 	nepali: "चिडिया घर",
	// 		// },
	// 	],
	// },
	{
		icon: "government",
		english: "Government Offices",
		nepali: "सरकारी कार्यालयहरु",
		children: [
			{
				value: "land_revenue",
				english: "Land Revenue",
				nepali: "मालपोत कार्यालय",
			},
			// {
			// 	value: "dao",
			// 	english: "District Office",
			// 	nepali: "जील्ला प्रशासन कार्यालय",
			// },
			{
				value: "court",
				english: "Court",
				nepali: "अदालत",
			},
			{
				value: "ministries",
				english: "Ministries",
				nepali: "मंत्रालय",
			},
			// {
			// 	value: "electricity",
			// 	english: "Electricity Office",
			// 	nepali: "विधुत प्राधिकरण कार्यालय",
			// },
			// {
			// 	value: "dcc",
			// 	english: "District Council",
			// 	nepali: "जिल्ला समन्व समिति कार्यालय",
			// },
			{
				value: "other_government",
				english: "Other Government Bodies",
				nepali: "अन्य कार्यालय",
			},
		],
	},
	// {
	// 	icon: "building",
	// 	english: "Organizations",
	// 	nepali: "संघ सँस्थाहरु",
	// 	children: [
	// 		{
	// 			value: "ngo",
	// 			english: "NGO",
	// 			nepali: "गैरसरकारी",
	// 		},
	// 		{
	// 			value: "ingo",
	// 			english: "INGO",
	// 			nepali: "अन्तर्राष्ट्रिय  गैरसरकारी",
	// 		},
	// 		{
	// 			value: "private",
	// 			english: "Private / Public",
	// 			nepali: "निजी / पब्लीक",
	// 		},
	// 	],
	// },
	// {
	// 	icon: "factory",
	// 	english: "Industries",
	// 	nepali: "उधोग तथा कलकारखाना",
	// 	children: [
	// 		{
	// 			value: "brick_industry",
	// 			english: "Brick Industry",
	// 			nepali: "इँटा भट्टा",
	// 		},
	// 		{
	// 			value: "sugar_mill",
	// 			english: "Sugar Mill",
	// 			nepali: "चिनी उधोग",
	// 		},
	// 		{
	// 			value: "mill",
	// 			english: "Mill",
	// 			nepali: "मिल",
	// 		},
	// 		{
	// 			value: "other_industry",
	// 			english: "Other Industry",
	// 			nepali: "अन्य उधोग",
	// 		},
	// 	],
	// },
	{
		icon: "police-station",
		english: "Security body Offices",
		nepali: "सुरक्षा निकाय सम्बन्धी",
		children: [
			{
				value: "police",
				english: "Police Station",
				nepali: "प्रहरी चौकी",
			},
			// {
			// 	value: "apf",
			// 	english: "APF Camp",
			// 	nepali: "एपीएफ व्यारेक",
			// },
			// {
			// 	value: "army",
			// 	english: "Army Camp",
			// 	nepali: "सैनिक व्यारेक",
			// },
			// {
			// 	value: "traffic",
			// 	english: "Traffic Office",
			// 	nepali: "ट्राफिक कार्यालय",
			// },
		],
	},
	// {
	// 	icon: "nature",
	// 	english: "Natural Resources",
	// 	nepali: "प्राकृतिक श्रोत",
	// 	children: [
	// 		{
	// 			value: "mining",
	// 			english: "Mine Area",
	// 			nepali: "खानी क्षेत्र",
	// 		},
	// 		{
	// 			value: "waterfall",
	// 			english: "Waterfall",
	// 			nepali: "झरना",
	// 		},
	// 		{
	// 			value: "cave",
	// 			english: "Cave",
	// 			nepali: "गुफा",
	// 		},
	// 		{
	// 			value: "lake",
	// 			english: "Lake",
	// 			nepali: "ताल",
	// 		},
	// 		{
	// 			value: "pond",
	// 			english: "Pond",
	// 			nepali: "पोखरी",
	// 		},
	// 		{
	// 			value: "main_water_source",
	// 			english: "Main Watersource",
	// 			nepali: "मुख्य पानी मुहान",
	// 		},
	// 		{
	// 			value: "others",
	// 			english: "Other Resources",
	// 			nepali: "अन्य श्रोत",
	// 		},
	// 	],
	// },
	{
		icon: "book",
		english: "Cultural Heritage",
		nepali: "साँस्कृतिक सम्पदाहरु",
		children: [
			{
				value: "temple",
				english: "Temple",
				nepali: "मठमन्दिर",
			},
			// {
			// 	value: "mosque",
			// 	english: "Mosque",
			// 	nepali: "मस्जिद",
			// },
			{
				value: "gumba",
				english: "Gumba",
				nepali: "गुम्बा",
			},
			// {
			// 	value: "dharmasala",
			// 	english: "Dharmasala",
			// 	nepali: "धर्मशाला",
			// },
			// {
			// 	value: "museum",
			// 	english: "Museum",
			// 	nepali: "संग्राहालय",
			// },
			{
				value: "palace",
				english: "Palace",
				nepali: "दरवार",
			},
			// {
			// 	value: "cultural_club",
			// 	english: "Cultural Club",
			// 	nepali: "साँस्कृतिक क्लब (नाचघर)",
			// },
		],
	},
	{
		icon: "book",
		english: "Hotel and Homestay",
		nepali: "होटल तथा होमस्टे",
		children: [
			{
				value: "homehotell",
				english: "Home Hotel",
				nepali: "आवासीय  होटेल",
			},
			// {
			// 	value: "resort",
			// 	english: "Resort",
			// 	nepali: "रिसोर्ट",
			// },
			// {
			// 	value: "homesty",
			// 	english: "Homestay",
			// 	nepali: "होमस्टे",
			// },
			{
				value: "resturent",
				english: "Resturent",
				nepali: "रेस्टुरेन्ट",
			},
		],
	},
	{
		icon: "book",
		value: "palika_related_office",
		english: "Palika Related Offices",
		nepali: "गाउँ/नगर केन्द्र र वडा कार्यालय",
	},
	// {
	// 	icon: "book",
	// 	value: "forest",
	// 	english: "Forest",
	// 	nepali: "सामुदायिक वन",
	// },
	// {
	// 	icon: "book",
	// 	value: "education",
	// 	english: "Educational Institution",
	// 	nepali: "शैक्षिक सँस्था",
	// },
	// {
	// 	icon: "book",
	// 	value: "offices",
	// 	english: "Organizations",
	// 	nepali: "संघ सँस्थाहरु",
	// },
	// {
	// 	icon: "book",
	// 	value: "infrastructure",
	// 	english: "Infrastructure",
	// 	nepali: "भौतिक पूर्वाधार",
	// },
	// {
	// 	icon: "book",
	// 	value: "security",
	// 	english: "Security body Offices",
	// 	nepali: "सुरक्षा निकाय सम्बन्धी",
	// },
	// {
	// 	icon: "book",
	// 	value: "economic",
	// 	english: "Financial Institutions",
	// 	nepali: "बैंक तथा वित्तीय संस्था",
	// },
	// {
	// 	icon: "book",
	// 	value: "palika_related_office",
	// 	english: "Municipal and Ward Office",
	// 	nepali: "गाउँ/नगर केन्द्र र वडा कार्यालय ",
	// },
	// {
	// 	icon: "book",
	// 	value: "public_places",
	// 	english: "Public Places",
	// 	nepali: "सार्वजनिक स्थल",
	// },
	// {
	// 	icon: "book",
	// 	value: "health",
	// 	english: "Health Institution",
	// 	nepali: "स्वास्थ केन्द्र",
	// },
];
export const ward = [
	{
		backgroundColor: "#f44336",
		key: 1,
		value: 1,
		ward: "वडा न. १",
		english: "ward 1",
		nepali: "वडा न. १",
	},
	{
		key: 2,
		value: 2,
		backgroundColor: "#ff5722",
		ward: "वडा न. २ ",
		english: "ward 2",
		nepali: "वडा न. २ ",
	},
	{
		key: 3,
		value: 3,
		backgroundColor: "#673ab7",
		ward: "वडा न. ३",
		english: "ward 3",
		nepali: "वडा न. ३",
	},
	{
		key: 4,
		value: 4,
		backgroundColor: "#e91e63",
		ward: "वडा न. ४",
		english: "ward 4",
		nepali: "वडा न. ४",
	},
	{
		key: 5,
		value: 5,
		backgroundColor: "#9c27b0",
		ward: "वडा न. ५",
		english: "ward 5",
		nepali: "वडा न. ५",
	},
	// {
	// 	key: 6,
	// 	value: 6,
	// 	backgroundColor: "#9c27b0",
	// 	ward: "वडा न. ६",
	// 	english: "ward 6",
	// 	nepali: "वडा न. ६",
	// },
	// {
	// 	key: 7,
	// 	value: 7,
	// 	backgroundColor: "#9c27b0",
	// 	ward: "वडा न. ७",
	// 	english: "ward 7",
	// 	nepali: "वडा न. ७",
	// },
];
