import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function Footer() {
	const today = new Date().getFullYear();
	const uiService = useHtmlClassService();
	const { currentState } = useSelector(
		(state) => ({ currentState: state.ward }),
		shallowEqual
	);
	const layoutProps = useMemo(() => {
		return {
			footerClasses: uiService.getClasses("footer", true),
			footerContainerClasses: uiService.getClasses("footer_container", true),
		};
	}, [uiService]);

	const getPartnerText = () => {
		if (currentState.selectedLanguage == "nepali") {
			// return "यो पालिका प्रोफाइल लोमन्थाङ गाउँपालिकाको साझेदारी-सङ्घीयता सहयोगको सहकार्यमा तयार पारिएको हो";
		}
		// return "This municipal digital profile has been developed in collaboration with Lomangthang Rural Municipality";
	};
	return (
		<div
			className={`footer bg-white py-5 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
			id="kt_footer"
		>
			<div className="container">
				<div className="d-flex flex-column align-items-center">
					<h6 className="text-primary">{getPartnerText()}</h6>
					<h6 className="mx-5">
						©{" "}
						{currentState.selectedLanguage == "nepali"
							? "सर्बाधिकरण लोमन्थाङ गाउँपालिका"
							: "All rights reserved Lomangthang Rural Municipality"}
					</h6>
					<h6 id="footer-smart">
						{" "}
						{currentState.selectedLanguage == "nepali"
							? "Developed and Design by"
							: "Developed and Design by "}
						<a href="https://www.stnepal.com">
							<img
								src="https://www.stnepal.com/wp-content/uploads/2021/11/SmartTech-01-1-1.png"
								alt="smart tech logo"
							/>
						</a>
					</h6>
				</div>
				{/* <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end">
						<img height="90px" src={toAbsoluteUrl("/media/logos/nepal_gov.png")} alt="Bhimdatta" />
						<div className="d-flex justify-content-center flex-column">
							<h3 className="text-danger">
								{currentState.selectedLanguage == "nepali"
									? "लोमन्थाङ गाउँपालिका"
									: "Lomangthang Rural Municipality"}
							</h3>
							<p className="text-danger">
								<strong>
									{currentState.selectedLanguage == "nepali"
										? "हेकुली, दांङ् लुम्बिनी प्रदेश, नेपाल "
										: "Hekuli, Dang Lumbini pradesh, Nepal"}
								</strong>
							</p>
						</div>
					</div> */}
			</div>
		</div>
	);
}
