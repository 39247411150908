import React, { useMemo, useState } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { StickySideMenu } from "../../../app/components/StickySideMenu";
import EmergencyContactModal from "../../../app/sections/EmergencyContactModal";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export function Layout({ children }) {
	const [modalShow, setModalShow] = useState(false);
	const selectedLanguage = useSelector((state) => state.ward.selectedLanguage);

	const uiService = useHtmlClassService();
	// Layout settings (cssClasses/cssAttributes)
	const layoutProps = useMemo(() => {
		return {
			layoutConfig: uiService.config,
			selfLayout: objectPath.get(uiService.config, "self.layout"),
			asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
			subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
			desktopHeaderDisplay: objectPath.get(
				uiService.config,
				"header.self.fixed.desktop"
			),
			contentCssClasses: uiService.getClasses("content", true),
			contentContainerClasses: uiService.getClasses("content_container", true),
			contentExtended: objectPath.get(uiService.config, "content.extended"),
		};
	}, [uiService]);

	const modalClose = () => {
		setModalShow(false);
	};

	return layoutProps.selfLayout !== "blank" ? (
		<>
			{/*begin::Main*/}
			{/* <HeaderMobile /> */}
			<div className="d-flex flex-column flex-root">
				{/*begin::Page*/}
				<div className="d-flex flex-row flex-column-fluid page">
					{/* {layoutProps.asideDisplay && (<Aside/>)} */}
					{/*begin::Wrapper*/}
					<div
						className="d-flex flex-column flex-row-fluid wrapper pl-0"
						id="kt_wrapper"
					>
						<Header />
						<div className="sticky-container">
							<Button
								className="sticky bg-danger"
								href=""
								data-tip
								data-for="registerTip"
								onClick={() => setModalShow(true)}
							>
								<i class="icon-emergency"></i>
							</Button>

							<ReactTooltip
								className="bg-white text-dark"
								id="registerTip"
								place="top"
								effect="solid"
							>
								{selectedLanguage == "nepali"
									? "आपतकालीन सम्पर्क"
									: "Emergency contact"}
							</ReactTooltip>

							<EmergencyContactModal show={modalShow} onHide={modalClose} />
						</div>

						{/*begin::Content*/}
						<div
							id="kt_content"
							className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
						>
							{/* {layoutProps.subheaderDisplay && <SubHeader />} */}
							{/*begin::Entry*/}
							{!layoutProps.contentExtended && (
								<div className="d-flex flex-column-fluid">
									{/*begin::Container*/}
									<div
										className={`${layoutProps.contentContainerClasses} custom-container`}
									>
										{children}
									</div>
									{/*end::Container*/}
								</div>
							)}

							{layoutProps.contentExtended && { children }}
							{/*end::Entry*/}
						</div>
						{/*end::Content*/}
						<Footer />
					</div>
					{/*end::Wrapper*/}
				</div>
				{/*end::Page*/}
			</div>
			<QuickUser />
			<QuickPanel />
			<ScrollTop />
			<StickySideMenu />
			{/* <StickyToolbar /> */}
			{/*end::Main*/}
			<LayoutInit />
		</>
	) : (
		// BLANK LAYOUT
		<div className="d-flex flex-column flex-root">{children}</div>
	);
}
